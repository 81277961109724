/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 76번째 로그에서는 컨테이너 OS 보틀로켓, 재난 경보 문자, 삼성의 탈AWS설 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "BottleRocket"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/aws/bottlerocket-open-source-os-for-container-hosting/"
  }, "Bottlerocket – Open Source OS for Container Hosting | AWS News Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/bottlerocket/"
  }, "Bottlerocket — Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://coreos.com/"
  }, "Open source, containers, and Kubernetes | CoreOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%B8%94%EB%A0%88%EC%9D%B4%EB%93%9C_%EC%84%9C%EB%B2%84"
  }, "블레이드 서버 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/coreos/toolbox"
  }, "coreos/toolbox: bring your tools with you")), "\n"), "\n", React.createElement(_components.h3, null, "RobinHood 장애"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://status.robinhood.com/"
  }, "Robinhood Status")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2020/03/10/robinhoods-third-outage-may-point-to-deeper-problems-in-its-tech-stack/"
  }, "Robinhood’s third outage may point to deeper problems in its tech stack | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/AskRobinhood/status/1238096609398812673"
  }, "Robinhood Help on Twitter: \"Market-wide trading has been halted for 15 minutes due to a 7% decline in the S&P 500. ... not by Robinhood.\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/stock/view/2020/03/248189/"
  }, "단독 - 뉴욕증시 패닉에 투자자들 몰린 키움증권 MTS 먹통 - 매일경제")), "\n"), "\n", React.createElement(_components.h3, null, "재난 경보 문자"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.apple.com/ko-kr/HT202743"
  }, "iPhone 및 Apple Watch의 긴급재난문자 및 정부 경보에 관하여 - Apple 지원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/senokay/status/1238276563637919748"
  }, "Kang Seonghoon on Twitter: \"재난·안전안내문자가 \"국제 표준에 부합하지 않아\" 문제를 일으킨다는 주장은 그간 몇 번 들은 얘긴데(가장 최근엔 https://t.co/pEs5HPKW4u), 들을 때마다 ", React.createElement(_components.em, null, "왜"), " 그리 되었는진 한 번도 못 들어 봤다. 이 참에 이동통신 보안 전문가인 지인의 도움을 받아 조사해 봤다. (물론 오류는 모두 내 탓이리라)\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://slownews.kr/75532"
  }, "한국식 ‘긴급재난문자’에 관한 몇 가지 상식 | 슬로우뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://moneys.mt.co.kr/news/mwView.php?no=2020031614368015105"
  }, "\"또 코로나야\"… 볼보차주들 뿔난 이유는? - 머니S")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.clien.net/service/board/cm_car/14696160"
  }, "DBM 재난 문자 수신 - 울산 11번 환자 , 보령 환자 정들겠네요... : 클리앙")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.chosun.com/site/data/html_dir/2020/02/25/2020022501478.html"
  }, "110조원 재산 워런 버핏, '드디어' 스마트폰 장만 - 조선닷컴 - 국제 > 미국")), "\n"), "\n", React.createElement(_components.h3, null, "삼성, 아마존 웹 서비스 탈출설"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.etnews.com/20200317000207"
  }, "삼성, 자체 클라우드 키운다 - 전자신문")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.joyent.com/"
  }, "Triton | Joyent")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.samsung.com/kr/%EC%82%BC%EC%84%B1%EC%A0%84%EC%9E%90-%EB%AF%B8%EA%B5%AD-%ED%81%B4%EB%9D%BC%EC%9A%B0%EB%93%9C-%EC%84%9C%EB%B9%84%EC%8A%A4-%EC%84%A0%EB%8F%84-%EA%B8%B0%EC%97%85-%EC%A1%B0%EC%9D%B4%EC%96%B8"
  }, "삼성전자, 미국 클라우드 서비스 선도 기업 ‘조이언트(Joyent)’ 인수 – Samsung Newsroom Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nodejs.org/en/download/"
  }, "Download | Node.js")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2019/4/22/18511148/apple-icloud-cloud-services-amazon-aws-30-million-per-month"
  }, "Apple’s cloud business is hugely dependent on Amazon - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://landing.google.com/sre/sre-book/chapters/production-environment/"
  }, "Chapter 2 The Production Environment at Google, from the Viewpoint of an SRE - Site Reliability Engineering")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.datacenterdynamics.com/en/news/zynga-abandons-data-centers-returns-to-aws/"
  }, "Zynga abandons data centers, returns to AWS - DCD")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://arstechnica.com/information-technology/2016/02/netflix-finishes-its-massive-migration-to-the-amazon-cloud/"
  }, "Netflix finishes its massive migration to the Amazon cloud | Ars Technica")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20191226070100063"
  }, "세종에 세계 최대 규모 네이버 데이터센터…2022년 준공 | 연합뉴스")), "\n"), "\n", React.createElement(_components.h3, null, "GitHub, npm 인수"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/2020-03-16-npm-is-joining-github/"
  }, "npm is joining GitHub - The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.npmjs.com/about"
  }, "npm About")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hub.docker.com/"
  }, "Docker Hub")), "\n"), "\n", React.createElement(_components.h3, null, "너굴 님 트윗"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/raccoonyy/status/1239990371616051200"
  }, "seungho kim on Twitter: \"카카오 알림톡과 % 문자의 비밀에 대해서 단편 소설을 하나 쓸 수도 있을 것 같다 퇴근 직전 발생한 장애를 여덟 시간 동안 처리한 후의 심정\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/kakaoteam/status/1239861227485945856"
  }, "카카오팀 on Twitter: \"오늘 오후 6시 43분부터 7시 16분까지 카카오톡 메시지 수발신이 원활하지 않은 현상이 있었습니다. 현재는 긴급 점검이 완료되어 정상 이용이 가능합니다. 불편을 겪으신 분들께 진심으로 사과 드립니다.\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devtalk.kakao.com/"
  }, "카카오 데브톡")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://business.kakao.com/info/kakaosync/"
  }, "카카오싱크 - 카카오 for 비즈니스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mobile.twitter.com/fortyfourbits"
  }, "44BITS 기술 블로그 👩‍💻👨‍💻 (@fortyfourbits) / Twitter")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
